import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import
import { UserService } from "../../service/user.service";
import { runInThisContext } from "vm";
import { ThemeProvider } from "styled-components";

export enum RegView {
  code = "code",
  reg = "reg",
}


interface Props {
  handleClose: () => any;
}

interface State {
  id: string;
  pass: string;
  pass_check: string;
  phone1: string;
  phone2: string;
  phone3: string;
  phone: string;

  bankname: string;
  banknumber: string;
  bankowner: string;
  exchange_pw: string;
  code: string;
  nick :string;
  mode: string;
}

export class Reg extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      id: "",
      pass: "",
      pass_check: "",
      phone1: "010",
      phone2: "",
      phone3: "",
      phone: "",

      bankname: "",
      banknumber: "",
      bankowner: "",
      exchange_pw: "",
      nick : "",
      code: "",
      mode : RegView.code
    };
  }

  
  handleCheckCode = async() => {
    if (
      this.state.code === undefined ||
      this.state.code.length <= 3 ||
      20 <= this.state.code.length
    ) {
      confirmAlert({
        title: "회원 가입",
        message: "가입 코드를 입력해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    let data = await this.userService.checkUserCode(this.state.code).then(s => s)
      if (data.status === "success") {
        this.setState({ mode  : RegView.reg })
        return;
      } else {
        confirmAlert({
          title: "회원 가입",
          message: "가입 코드를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.setState({ id: "" });
              },
            },
          ],
        });
        return;
      }
  };


  handleCheck = () => {
    if (
      this.state.id === undefined ||
      this.state.id.length <= 3 ||
      20 <= this.state.id.length
    ) {
      confirmAlert({
        title: "회원 가입",
        message: "아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.regCheckID(this.state.id).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원 가입",
          message: "사용 할수있는 아이디 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원 가입",
          message: "사용 불가능한 아이디 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.setState({ id: "" });
              },
            },
          ],
        });
        return;
      }
    });
  };

  handleReg = () => {
    if (
      this.state.id === undefined ||
      this.state.id.length <= 3 ||
      20 <= this.state.id.length
    ) {
      confirmAlert({
        title: "회원 가입",
        message: "아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (
      this.state.pass == null ||
      this.state.pass_check == null ||
      this.state.pass !== this.state.pass_check ||
      this.state.pass_check.length <= 3 

    ) {
      confirmAlert({
        title: "회원 가입",
        message: "비밀번호를 확인해주세요 . 비밀번호는 최소 6자리를 사용하셔야합니다",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    let phone = this.state.phone1 + this.state.phone2 + this.state.phone3;
    if (phone == null || phone.length <= 10) {
      confirmAlert({
        title: "회원 가입",
        message: "휴대폰 번호를 확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.bankname == null) {
      confirmAlert({
        title: "회원 가입",
        message: "은행명을 확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.banknumber == null) {
      confirmAlert({
        title: "회원 가입",
        message: "계좌 번호를  확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });

      return;
    }

    if (this.state.bankowner == null) {
      confirmAlert({
        title: "회원 가입",
        message: "예금주 이름을 확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });

      return;
    }

    this.userService
      .regUser({
        id: this.state.id,
        pw: this.state.pass,
        pass_check: this.state.pass_check,
        phone: phone,
        bankname: this.state.bankname,
        banknumber: this.state.banknumber,
        bankowner: this.state.bankowner,
        exchange_pw: this.state.bankowner,
        code: this.state.code,
        nick: this.state.nick,
      })
      .then((data: any) => {
        if (data.status === "alread_have_user") {
          confirmAlert({
            title: "회원 가입",
            message: "생성할수 없는 유저아이디 입니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });
          return;
        } else if (data.status === "cant_find_code") {
          confirmAlert({
            title: "회원 가입",
            message: "회원 가입코드를 확인해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });

          return;
        } else {
          confirmAlert({
            title: "회원 가입",
            message: "회원 가입에 성공하였습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });

          return;
        }
      });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: 'none',
          border: "none",
          width: "none",
        }}
        
        overlayStyle={{
          overflow: 'scroll'
        }}
      >
        {(close) => (
     
     
     <div className="modal fade show" id="_Register_Modal"  role="dialog" aria-labelledby="_Register_ModalTitle" aria-hidden="true" style={{paddingRight: '17px', display: 'block'}}>
     <div className="modal-dialog modal-dialog-centered" role="document">
       <div className="modal-content"  style={{ background : '#000'}}>
           <div className="modal-body">
             <div className="_top_title_overlay">
               <label className="m-0"><i className="fa fa-edit" aria-hidden="true"></i> 회원가입</label>
               <button data-dismiss="modal" className="mdl-close" style={{zIndex: 5}}  onClick={()=>{this.props.handleClose()}}><i className="fa fa-times" aria-hidden="true"  ></i></button>
             </div>

             <div className="_inner_modal_content">
               <div className="_inner_title py-0 pb-3 mb-3">
                 <label>코인 슬롯에 오신것을 환영합니다.</label>
               </div>

               <div className="_inner_inputs">
                 <div className="_custom_group_inputs">
                   <span>아이디</span>
                   <input type="text" name="" placeholder="아이디"  value={this.state.id} onChange={(e: any) => { this.setState({ id: e.target.value })}} />
                   <button onClick={()=> this.handleCheck()}>중복체크</button>
                 </div>
                 {/* <div className="_custom_group_inputs">
                   <span>닉네임</span>
                   <input type="text" name="" placeholder="닉네임"   value={this.state.pass} onChange={(e: any) => { this.setState({ pass: e.target.value }); }} />
                 </div> */}
                 <div className="_custom_group_inputs" >
                   <span>비밀번호</span>
                   <input type="text" name=""  placeholder="비밀번호" style={{width: '68%'}}  value={this.state.pass} onChange={(e: any) => { this.setState({ pass: e.target.value }); }} />
                 </div>
                 <div className="_custom_group_inputs">
                   <span>비밀번호확인</span>
                   <input type="text" name="" placeholder="비밀번호확인" style={{width: '68%'}}  value={this.state.pass_check} onChange={(e: any) => { this.setState({ pass_check: e.target.value }); }} />
                 </div>

                 <div className="_custom_group_inputs">
                   <span>전화번호</span>
                   <input type="text" name="" placeholder="*통화가능한번호 필수입력" value={this.state.phone1}  onChange={(e: any) => { this.setState({ phone1: e.target.value }); }} />
                   <button>중복체크</button>
                 </div>

                 <small className="text-white float-left" style={{marginLeft: '30%'}}>( 실시간통화 후 인증번호 안내 )</small>

                 <div className="clearfix mb-3"></div>

                 <div className="_custom_group_inputs">
                   <span>은행명</span>
                   <select id="bank" name="bank" style={{width: '68%'}}  value={this.state.bankname}   onChange={(e: any) => { this.setState({ bankname: e.target.value });}} >
                          <option value="">은행선택</option>
                            <option value="기업은행">기업은행</option>
                            <option value="국민은행">국민은행</option>
                            <option value="외환은행">외환은행</option>
                            <option value="수협">수협</option>
                            <option value="농협중앙회">농협중앙회</option>
                            <option value="단위농협">단위농협</option>
                            <option value="우리은행">우리은행</option>
                            <option value="SC제일은행">SC제일은행</option>
                            <option value="한국씨티은행">한국씨티은행</option>
                            <option value="대구은행">대구은행</option>
                            <option value="부산은행">부산은행</option>
                            <option value="광주은행">광주은행</option>
                            <option value="제주은행">제주은행</option>
                            <option value="전북은행">전북은행</option>
                            <option value="경남은행">경남은행</option>
                            <option value="새마을금고">새마을금고</option>
                            <option value="신협중앙회">신협중앙회</option>
                            <option value="우체국">우체국</option>
                            <option value="하나은행">하나은행</option>
                            <option value="신한은행">신한은행</option>
                            <option value="카카오뱅크">카카오뱅크</option>
                            <option value="주택은행">주택은행</option>
                            <option value="산업은행">산업은행</option>
                           </select>
                 </div>

                 <div className="_custom_group_inputs">
                   <span>계좌번호</span>
                   <input type="text" name="" placeholder="계좌번호" style={{width: '68%'}}  value={this.state.banknumber} onChange={(e: any) => { this.setState({ banknumber: e.target.value }); }}  />
                 </div>

                 <div className="_custom_group_inputs">
                   <span>예금주</span>
                   <input type="text" name="" placeholder="예금주"  style={{width: '68%'}}   value={this.state.bankowner} onChange={(e: any) => { this.setState({ bankowner: e.target.value }); }} />
                 </div>
                 <div className="_custom_group_inputs">
                   <span>가입코드</span>
                   <input type="text" name="" placeholder="가입코드"  style={{width: '68%'}}   value={this.state.code} onChange={(e: any) => { this.setState({ code: e.target.value }); }} />
                 </div>
                 <div className="text-center mt-4">
                   <button className="_modal_bottom_button_type_1" onClick={()=> this.handleReg()}>가입신청</button>
                 </div>
               </div> 
             </div>
           </div> 
       </div> 
     </div>
   </div>
          
            
        )}
      </Popup>
    );
  }
}
