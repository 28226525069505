import React, { Component } from "react";
import styled from "styled-components";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import { confirmAlert } from "react-confirm-alert"; // Import
import Typography from "@material-ui/core/Typography";
import { SubMenu } from "./submenu";

import { UserService } from "../../service/user.service";

import { ConvertDate, HelpStatus, ConvertDate2 } from "../../utility/help";
import { runInThisContext } from "vm";
import { ThreeDRotationSharp } from "@material-ui/icons";

export enum helpView {
  none = "none",
  write = "write",
  view = "view",
  detail = "detail",
}

const CustomTableCell = styled(TableCell)`
  color: white;
  padding: 4px;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});


interface Props {
  handleClose: () => any;
  handleActive: (active:string) => any;

}

interface State {
  helps: any;
  mode: string;

  title: string;
  contents: string;
  detail: any;
}

export class Help extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = { helps: [], mode: helpView.view, title: "", contents: "" , detail : {}};
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_help_list().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });
  };

  doReadMessage = async (id : any)=>{
    await this.userService.do_help_message_read(id)
  }

  doDelAll = async ()=>{
    await this.userService.del_all_help()

    window.location.reload()
  }

  
  
  handleSaveHelp = (title: string, contents: string) => {

    if(title == '' ){
      confirmAlert({
        title: "고객센터",
        message: "타이틀을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return 
    }
    if(contents == ''){
      confirmAlert({
        title: "고객센터",
        message: "내용을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return 
    }

    this.userService.user_wirte_help(title, contents).then((date: any) => {
      if (date.status === "success") {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.handleGetNotices();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });

    this.props.handleClose();
  };

  render() {
    let helps = this.state.helps;

    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "#000",
          border: "none",
          width: "none",
        }}
        onClose={() => {}}
      >
        {(close) => (

          
<div className="modal fade show" id="WithdrawalAndDepositModal"  role="dialog" aria-labelledby="WithdrawalAndDepositModalTitle" style={{paddingRight: '17px', display: 'block'}} aria-modal="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-body">
                  <button data-dismiss="modal" className="mdl-close pt-0 p-3 " style={{float: 'right', background: 'transparent', color: '#fff', border: 'none', fontSize: '20px', paddingTop: '0!important'}}  onClick={()=> window.location.reload()}><i className="fa fa-times" aria-hidden="true" ></i></button>
                  <h4>고객센터 <span style={{color: '#555', fontSize: '20px'}}>Help</span></h4>
                  {/* <img src="/light/images/background/site-flash.svg" className="w-100" style={{marginTop: '-25px'}} /> */}
                  <div className="_notice_tables">
                  {
                      this.state.mode ===  helpView.view && (
                        <div className="_service_modal_one" >	
                          <table className="w-100 text-center _table_design_one">
                            <tr>
                              <td>제 목</td>
                              <td>작성일</td>
                              <td>상태</td>
                            </tr>
                            {helps.map((row: any) => {
                              return (
                                <tr className=""  onClick={()=>{
                                    this.setState({detail : row, mode :  helpView.detail})
                                    this.doReadMessage(row._id)
                                  }}>
                                  <td className="td_subject text-left" style={{color : row.title_color}}>{row.title}</td>
                                  <td className="td_name sv_use hidden visible-lg text-center" style={{width:'15%'}}><span>관리자</span></td>
                                  <td className="td_date  text-center" style={{width:'15%'}}>{ConvertDate(row.regDate)}</td>
                                </tr>
                              )
                            })}
              
                          </table>
                          <div className="text-center mt-4">
                            <button className="_modal_bottom_button_type_1 _open_service_write"  onClick={()=>{this.setState({detail : {}, mode :  helpView.write})}} >글쓰기</button>
                          </div>
                        </div>
                        )
                    }

                    {
                      this.state.mode ===  helpView.detail && (

                        <div className="_service_modal_write" >
                        <div className="_form_tables my-3">
                          <table className="w-100">
                            <tr>
                              <td>제목</td>
                              <td>
                                <div className="d-flex _table_form_inputs">{this.state.detail.title}</div>
                              </td>
                            </tr>
                            <tr>
                              <td>내용	</td>
                              <td>
                                <div className="d-flex _table_form_inputs">
                                     <div dangerouslySetInnerHTML={{ __html: this.state.detail.contents }}     ></div>        
                                </div>
                              </td>
                            </tr>
                            {
                              this.state.detail.ref && (
                                <tr>
                                  <td>답변	</td>
                                  <td>
                                    <div className="d-flex _table_form_inputs">	
                                      <div dangerouslySetInnerHTML={{ __html: this.state.detail.ref.contents }}     ></div>        

                                    </div>
                                  </td>
                                </tr>
                              )
                            }
                            
                          </table>
                          <div className="text-center mt-4 d-flex">
                            <button className="_modal_bottom_button_type_1 _service_goBack"  onClick={() => { this.setState({ mode: helpView.view }); }}>뒤로가기</button>
                          </div>
                        </div>
                      </div>
          
                      )
                    }
                    
                    {
                      this.state.mode ===  helpView.write && (
                        <div className="_service_modal_write" >
                          <div className="_form_tables my-3">
                            <table className="w-100">
                              <tr>
                                <td>제목</td>
                                <td>
                                  <div className="d-flex _table_form_inputs">	
                                    <input type="text" name="" className="w-100 _deposit_input" value={this.state.title} onChange={(e) => this.setState({ title: e.target.value  })}

                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>내용	</td>
                                <td>
                                  <div className="d-flex _table_form_inputs">	
                                    <textarea className="w-100 py-1"
                                      value={this.state.contents}
                                      onChange={(e) =>
                                        this.setState({
                                          contents: e.target.value,
                                        })
                                      } 
                                    ></textarea>
                                  </div>
                                </td>
                              </tr>
                            </table>
                            <div className="text-center mt-4 d-flex">
                              <button className="_modal_bottom_button_type_1 _service_goBack"  onClick={() => { this.setState({ mode: helpView.view }); }}>뒤로가기</button>
                              <button className="_modal_bottom_button_type_1 "  onClick={() => { this.handleSaveHelp( this.state.title, this.state.contents);}}>저장하기</button>
                            </div>
                          </div>
                        </div>
                      )
                    }
           
               
                </div>
            </div>
            </div>
          </div>
        </div>
       
        )}
      </Popup>
    );
  }
}
