import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ViewGame  } from "./viewgame";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import { ConvertDate2, ConverMoeny, ConvertDate } from "../../utility/help";

import { SlotService } from "../../service/slot.service";
import { BalanceService } from "../../service/balance.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

interface Props {
  gameName : string
}

interface State {
  mode: string;
  slots: any;
  casinos: any;
  gameCode : any;
  games : any; 
  name : string;
  withdraws: any;
  deposits: any;
}

export class Companylist extends Component<Props, State> {
  slotService = new SlotService();
  balanceService = new BalanceService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: Mode.none,
      slots: [],
      gameCode : this.props.gameName,
      name : '',
      games : [],
      withdraws: [],
      deposits: [],
      casinos: [],
      
    };

    
    this.balanceService.get_balance_deposit_lock().then((s) => {
      if (s.status === "success") {
        this.setState({ deposits: s.deposits });
      }
    });

    this.balanceService.get_balance_withdraw_lock().then((s) => {
      if (s.status === "success") {
        this.setState({ withdraws: s.withdraws });
      }
    });

    if(this.props.gameName !== ''){
      this.handleGame(this.props.gameName, '')
    }
   
  }


  componentDidMount() {
    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ casinos: s.casiono});
      }
    });
  }

  handleOpenSlot = (code: string, company : string ) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      } else if (data.status === "fix_server") {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoFix,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      } else {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoPermission,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      }
    });
  };


  handleGame = (name: string, nameKo: string) => {
    this.slotService.get_slot_by_company(name).then((s: any) => {
      if (s.status === "success") {
        const oriArray = s.games 
        this.setState({ games:  s.games , mode: Mode.game , gameCode : name, name : nameKo});
      }
    });
  };

  // RenderSlot = (info : any) => {


  //   return (

  //     <div className="col-md-4 col-6 col-lg-2 game-box">
  //       <div className="col-image-holder">
  //         <div className="overlay"></div>
  //           <img src={info.mobileImg} /> 
  //           <div className="play-icon-holder">
  //           <img src="/coin/assets/images/icon/play.svg" className="play-icon" /> 
  //         </div>
  //       </div>
  //       <div className="text-holder">{info.nameKo}</div>
  //     </div>


  //     // <div className="modal fade show" id="WithdrawalAndDepositModal"  role="dialog" aria-labelledby="WithdrawalAndDepositModalTitle" style={{ display: 'block'}} aria-modal="true">
  //     // <div className="modal-dialog modal-dialog-centered modal-lg" role="document" style={isBrowser? {maxWidth : '1440px'} : {maxWidth : '100%'} }>
  //     //   <div className="modal-content">
  //     //       <div className="modal-body">
  //     //         <button data-dismiss="modal" className="mdl-close pt-0 p-3 " style={{float: 'right', background: 'transparent', color: '#fff', border: 'none', fontSize: '20px'}}   onClick={()=>{

  //     //           this.setState({  mode: Mode.none});

  //     //         }}><i className="fa fa-times" aria-hidden="true"></i></button>
  //     //         {/* <h4>출금신청 <span style={{color: '#555', fontSize: '20px'}}>Money Change</span></h4> */}
  //     //         <img src="/light/images/background/site-flash.svg" className="w-100" style={{marginTop: '-25px'}} />
        
  //     //         <div className="_deposit_tabs" >	        		
  //     //             <div className="_front_game" style={{ overflow: 'auto', height: '640px'}}>
  //     //               {this.state.games.map((game: any) => {

  //     //                 return (
  //     //                   <div className="_game_list"  style={isBrowser ? {width: '10%', textAlign: 'center'} : { width: '30%', textAlign: 'center',} } onClick={()=> this.handleOpenSlot(game.code, game.gameCompany)}>
  //     //                     <div className="_bg_start" >GAME START</div>
  //     //                     <img src={game.imgUrl} />
  //     //                     <span>{game.nameKo}</span>
  //     //                   </div>
  //     //                 )
  //     //               })}
  //     //           </div>
  //     //           <div className="_modal_box_infomation">
  //     //             <p>23:50 ~ 00:30, 휴일 다음 첫 영업일 새벽에는 은행점검으로 인해 계좌이체가 지연될 수 있습니다.</p>
  //     //             <p>위 시간 이외에도 몇몇 은행은 추가적 점검시간이 따로 있으니 이점 유념하시기 바랍니다.</p>
  //     //           </div>
  //     //           <div className="text-center mt-4">
  //     //           </div>
  //     //         </div>
  //     //       </div>
  //     //     </div>
  //     //   </div>
  //     // </div>


  //   );
  // };

  
  RenderCasino = (info : any) => {
    return (
      
     <div className="col-md-4 col-6 col-lg-2 game-box"  onClick={()=>{
       if (info.used === "y") {
        //  this.handleGame(info.code, info.nameKo)
         this.handleOpenSlot(info.code, info.gameCompany)         
       } else {
         confirmAlert({
           title: "점검중",
           message: "현재 해당게임은 점검중입니다 .",
           buttons: [
             {
               label: "확인",
               onClick: () => {},
             },
           ],
         });
       } 
     }}>
       <div className="col-image-holder">
         <div className="overlay"></div>
           <img src={info.mobileImg} /> 
           <div className="play-icon-holder">
           <img src="/coin/assets/images/icon/play.svg" className="play-icon" /> 
         </div>
       </div>
       <div className="text-holder">{info.nameKo}</div>
     </div>

    );
  };
  

  RenderGame = (info : any) => {
     return (
       
      
      <div className="col-md-4 col-6 col-lg-2 game-box"  onClick={()=>{
        if (info.used === "y") {
          this.handleGame(info.code, info.nameKo)
        } else {
          confirmAlert({
            title: "점검중",
            message: "현재 해당게임은 점검중입니다 .",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });
        } 
      }}>
        <div className="col-image-holder">
          <div className="overlay"></div>
            <img src={info.mobileImg} /> 
            <div className="play-icon-holder">
            <img src="/coin/assets/images/icon/play.svg" className="play-icon" /> 
          </div>
        </div>
        <div className="text-holder">{info.nameKo}</div>
      </div>

     );
   };

   RenderSlot = () => {

    if(this.state.games.length <= 0 || this.state.mode !==  Mode.game) {
      return (<></>)
    }

    return (

      <div className="modal fade show" id="WithdrawalAndDepositModal"  role="dialog" aria-labelledby="WithdrawalAndDepositModalTitle" style={{ display: 'block', zIndex : 9999}} aria-modal="true">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document" style={isBrowser? {maxWidth : '1440px'} : {maxWidth : '100%'} }>
        <div className="modal-content">
            <div className="modal-body">
              <button data-dismiss="modal" className="mdl-close pt-0 p-3 " style={{float: 'right', background: 'transparent', color: '#fff', border: 'none', fontSize: '20px'}}   onClick={()=>{

                this.setState({  mode: Mode.none});

              }}><i className="fa fa-times" aria-hidden="true" style={{color : 'red' , fontSize : 40}}></i></button>
              {/* <h4>출금신청 <span style={{color: '#555', fontSize: '20px'}}>Money Change</span></h4> */}
              <img src="/light/images/background/site-flash.svg" className="w-100" style={{marginTop: '-25px', visibility : 'hidden'}} />
        
              <div className="_deposit_tabs" >	        		
                  <div className="_front_game" style={{ overflow: 'auto', height: '640px'}}>
                    {this.state.games.map((game: any) => {

                      return (
                        <div className="_game_list"  style={isBrowser ? {width: '200px', textAlign: 'center', background : 'none'} : { width: '30%', textAlign: 'center', background : 'none'} } onClick={()=> this.handleOpenSlot(game.code, game.gameCompany)}>
                          <div className="_bg_start" >GAME START</div>
                          <img src={game.imgUrl} />
                          <span>{game.nameKo}</span>
                        </div>
                      )
                    })}
                </div>
                <div className="_modal_box_infomation">
                  <p>23:50 ~ 00:30, 휴일 다음 첫 영업일 새벽에는 은행점검으로 인해 계좌이체가 지연될 수 있습니다.</p>
                  <p>위 시간 이외에도 몇몇 은행은 추가적 점검시간이 따로 있으니 이점 유념하시기 바랍니다.</p>
                </div>
                <div className="text-center mt-4">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    );
  };
  

  render() {

    
  
      return (

        <>
          
    

    <div className="container">
      <div style={{paddingLeft: '50px', position: 'relative', marginTop: '20px', color: '#fff'}}>
        <img src="/coin/assets/images/icon/live.svg" style={{position: 'absolute', left: 0, top:' 5px'}}/>
        <h3 style={{fontSize: '15px', fontFamily: 'Montserrat', fontWeight: 900}} className="m-0">Casino</h3>
        <small>Casino Games</small>
      </div>

      <div className="row">
        
        {this.state.casinos.map((row: any) => this.RenderCasino(row))} 

        {this.RenderSlot()}

      </div>
      <div style={{paddingLeft: '50px', position: 'relative', marginTop: '20px', color: '#fff'}}>
        <img src="/coin/assets/images/icon/live.svg" style={{position: 'absolute', left: 0, top:' 5px'}}/>
        <h3 style={{fontSize: '15px', fontFamily: 'Montserrat', fontWeight: 900}} className="m-0">SLOT</h3>
        <small>26+ Games</small>
      </div>

      <div className="row">
        
        {this.state.slots.map((row: any) => this.RenderGame(row))} 

        {this.RenderSlot()}

      </div>
    </div>

      <div className="container">
        <hr />
        <div className="py-5" style={{borderTop: '1px solid #2e2e2e', borderBottom: '1px solid #2e2e2e', display: 'flex', alignItems: 'center'}}>
          <ul className="footer-carousel owl-carousel owl-theme" style={{margin: 0}}>
            <li className="partners partner-1"></li>
            <li className="partners partner-2"></li>
            <li className="partners partner-3"></li>
            <li className="partners partner-5"></li>
            <li className="partners partner-6"></li>
            <li className="partners partner-7"></li>
            <li className="partners partner-8"></li>
            <li className="partners partner-9"></li>
            <li className="partners partner-10"></li>
            <li className="partners partner-11"></li>
            <li className="partners partner-12"></li>
            <li className="partners partner-13"></li>
            <li className="partners partner-14"></li>
            <li className="partners partner-15"></li>
            <li className="partners partner-16"></li>
            <li className="partners partner-17"></li>
            <li className="partners partner-18"></li>
            <li className="partners partner-18"></li>
            <li className="partners partner-20"></li>
            <li className="partners partner-21"></li>
            <li className="partners partner-22"></li>
            <li className="partners partner-23"></li>
            <li className="partners partner-24"></li>
            <li className="partners partner-25"></li>
            <li className="partners partner-26"></li>
            <li className="partners partner-27"></li>
            <li className="partners partner-28"></li>
            <li className="partners partner-28"></li>
            <li className="partners partner-30"></li>
            <li className="partners partner-31"></li>
            <li className="partners partner-32"></li>
            <li className="partners partner-33"></li>
            <li className="partners partner-34"></li>
            <li className="partners partner-35"></li>
            <li className="partners partner-36"></li>
            <li className="partners partner-37"></li>
            <li className="partners partner-38"></li>
            <li className="partners partner-39"></li>
            <li className="partners partner-40"></li>
          </ul>
        </div>

        <div className="row footer my-3 text-center w-100" style={{textAlign: 'center', justifyContent: 'center'}}>
          <small>Copyright 2021 Coin Slot . All right reserved.</small>
        </div>
      </div>

    </>

        // <div className="casino_slot_main">
        //   <div className="_banner">
        //     <div className=""></div>
        //   </div>

        //   <div className="gametab _hover">
        //     <div id="id_tab_slot" className="_on">
        //       {isBrowser  &&  this.RenderDeposit()} 

        //     </div>

        //     <div id="id_tab_casino" >
        //       {isBrowser  && this.RenderWithdraw()}
        //     </div>
        //      {/*  */}
        //   </div>
        //    {this.state.mode === Mode.game && this.RenderGame()}  

        //   <div className="cont">
        //     <div className="card">
        //       <div className="slot_main active">
        //         {this.state.slots.map((row: any) => this.RenderSlot(row))} 

        //       </div>
        //     </div>
        //   </div>
        // </div>

        );
  
    
  }
}
