import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import
import { UserService } from "../../service/user.service";
import { runInThisContext } from "vm";
import { ThemeProvider } from "styled-components";
import { ConverMoeny } from "../../utility/help";

import { BalanceService } from "../../service/balance.service";

export enum RegView {
  code = "code",
  reg = "reg",
}


interface Props {
    user : any;
    handleClose: () => any;
}

interface State {
  point : number;
  balance: number;
}

export class Point extends Component<Props, State> {
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);

    this.state = {
        point :0,
        balance: 0
    };
  }

  
  
  handleChangeToBalance = () => {
    if(this.state.balance <= 0 ){
        confirmAlert({
            title: "벨런스",
            message: "벨런스를 입력해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });
          return ;
    }

    confirmAlert({
        title: "벨런스",
        message: "보유머니를 입금 하시겠습니까?",
        buttons: [
          {
            label: "확인",
            onClick: () => {

                this.balanceService.user_balance_to_point(this.state.balance).then((data: any) => {
                    if (data.status === "success") {
                      confirmAlert({
                        title: "벨런스",
                        message: "벨런스를 금고머니로 변경하였습니다.",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              window.location.reload()
                            },
                          },
                        ],
                      });
            
                    }
                });
            },
          },

          {
            label: "취소",
            onClick: () => {

            },
          },

        ],
      });
     

  };

  handleChangeToPoint = () => {
    if( this.state.point <= 0 ){
        confirmAlert({
            title: "금고머니",
            message: "금고머니를 입력해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });
          return ;
    }

    confirmAlert({
        title: "금고머니",
        message: "금고머니를 출금하시겠습니까?",
        buttons: [
          {
            label: "확인",
            onClick: () => {
                this.balanceService.user_point_to_money(this.state.point).then((data: any) => {
                    if (data.status === "success") {
                        confirmAlert({
                            title: "금고머니",
                            message: "금고머니를 벨런스로 변경하였습니다.",
                            buttons: [
                              {
                                label: "확인",
                                onClick: () => {
                                  window.location.reload()
                                },
                              },
                            ],
                          });
                  }
                });
            },
          },

          {
            label: "취소",
            onClick: () => {

            },
          },

        ],
      });
     

  };


  render() {
    return (
      <Popup
      // key={`main_popup_note_${pop.idx}`}
      open={true}
      contentStyle={{
        zIndex: 99,
        background: "none",
        border: "none",
        width: "none",
      }}
      onClose={()=>{this.props.handleClose()}}
    >
      {(close) => (

      <div className="modal fade show" id="WithdrawalAndDepositModal"  role="dialog" aria-labelledby="WithdrawalAndDepositModalTitle" style={{paddingRight: '17px', display: 'block'}} aria-modal="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
              <div className="modal-body">
                <button data-dismiss="modal" className="mdl-close pt-0 p-3 " style={{float: 'right', background: 'transparent', color: '#fff', border: 'none', fontSize: '20px'}}  onClick={()=>{this.props.handleClose()}}><i className="fa fa-times" aria-hidden="true"></i></button>
                <h4>금고 <span style={{color: '#555', fontSize: '20px'}}>Bank</span></h4>
                {/* <img src="/light/images/background/site-flash.svg" className="w-100" style={{marginTop: '-25px'}} /> */}
                {/* <div className="_menu_modal_head_button d-flex">
                  <button className="_menu_tabs_btn _openDepositTabs active">입금신청</button>
                </div> */}
                <div className="_deposit_tabs" >	        		
                  <div className="_modal_box_infomation">
                    <p style={{color: '#ffd200'}}>수표입금시 입금처리 절대 되지 않습니다.</p>
                    <p><span style={{color: '#00f0ff'}}>최소 입금액은 3만원이상, 만원단위</span>로 신청가능, 입금전 반드시 계좌문의 하시기바랍니다.</p>
                  </div>
                  <div className="_form_tables my-3">
                    <table className="w-100">
                      <tbody>
                      <tr>
                        <td style={{width: '20%'}}>금고 보유금 	</td>
                        <td>
                          <div className="_table_form_inputs mt-2" style={{textAlign : 'center'}}>	
                            {ConverMoeny(this.props.user.point)}
                          </div>
                
                        </td>
                      </tr>
                      <tr>
                        <td style={{width: '20%'}}>금고입금	</td>
                        <td>
                          <div className="d-flex _table_form_inputs">	
                            <input type="text" name="" className="w-100 _deposit_input" value={this.state.balance} onChange={(e: any) => {
                              
                              
                              this.setState({ balance :  Number(e.target.value) })}}/>
                            
                          </div>
                          <div className="_table_form_inputs mt-2">
                      
                            <button    onClick={()=> this.handleChangeToBalance()}>입금하기
                            <br />

                            </button>

                          </div>
                        </td>
                      </tr>
            
                      <tr>
                        <td style={{width: '20%'}}>
                          금고출금	
                          </td>
                        <td>
                          <div className="d-flex _table_form_inputs">	
                            <input type="text" name="" className="w-100 _deposit_input" value={this.state.point} onChange={(e: any) => {
                              
                              
                              this.setState({ point :  Number(e.target.value) })}}/>
                            
                          </div>
                          <div className="_table_form_inputs mt-2">
                       
                            <button      onClick={()=> this.handleChangeToPoint()}>출금하기</button>

                          </div>
                        </td>
                      </tr>
                    </tbody></table>
                  </div>
                  <div className="_modal_box_infomation">
                    <p>23:50 ~ 00:30, 휴일 다음 첫 영업일 새벽에는 은행점검으로 인해 계좌이체가 지연될 수 있습니다.</p>
                    <p>위 시간 이외에도 몇몇 은행은 추가적 점검시간이 따로 있으니 이점 유념하시기 바랍니다.</p>
                  </div>
                  <div className="text-center mt-4">
                    {/* <button className="_modal_bottom_button_type_1 " onClick={()=>this.handleDoDeposit()}>입금신청</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      )}
    </Popup>
    );
  }
}
